export const call_api = (endpoint) => {
    return fetch(endpoint, { mode: 'cors', headers: { "Authorization": `Bearer ${localStorage.getItem("access_token")}` } })
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error("Token expired")
            }
        })
}

export const call_api_post = (endpoint, data) => {
    return fetch(endpoint, { method: 'POST', body: new URLSearchParams(data), mode: 'cors', headers: { "Authorization": `Bearer ${localStorage.getItem("access_token")}` } })
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error("Token expired")
            }
        })
}

export const call_api_delete = (endpoint) => {
    return fetch(endpoint, { method: 'DELETE', mode: 'cors', headers: { "Authorization": `Bearer ${localStorage.getItem("access_token")}` } })
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error("Token expired")
            }
        })
}

export const call_api_reauth = (endpoint) => {
    return fetch(`${process.env.REACT_APP_BE_SERVER}/refresh`, { mode: 'cors', headers: { "Authorization": `Bearer ${localStorage.getItem("refresh_token")}` } })
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error("Invalid credential")
            }
        })
        .then((data) => localStorage.setItem("access_token", data.access_token))
        .then(() => call_api(endpoint))
}

export const call_api_post_reauth = (endpoint, data) => {
    return fetch(`${process.env.REACT_APP_BE_SERVER}/refresh`, { mode: 'cors', headers: { "Authorization": `Bearer ${localStorage.getItem("refresh_token")}` } })
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error("Invalid credential")
            }
        })
        .then((data) => localStorage.setItem("access_token", data.access_token))
        .then(() => call_api_post(endpoint, data))
}

export const call_api_without_auth = (endpoint) => {
    return fetch(endpoint)
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error("Invalid response")
            }
        })
}