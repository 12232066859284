// reducers/index.js

/** root reducer */
import { combineReducers } from "redux";
import { user } from "./userInfo";
import { post } from "./post";

const rootReducer = combineReducers({
  user,
  post
});

export default rootReducer;
