import { Button, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import { useDispatch, useSelector } from 'react-redux';
import { updateSigninState, updateInfo } from '../reducers/userInfo';
import { useNavigate } from "react-router-dom";
import { DiscordLoginButton } from "react-social-login-buttons";
import { call_api, call_api_reauth } from '../utils';
import { resetPost } from "../reducers/post";


const TitleBar = () => {
    const user = useSelector(state => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let loginButton;
    let writePostButton;
    let myPostsButton;
    let logoutButton;
    if (localStorage.getItem("access_token") && !user.isSignedIn) {
        call_api(`${process.env.REACT_APP_BE_SERVER}/myinfo`)
            .then((data) => {
                dispatch(updateSigninState(true));
                dispatch(updateInfo(data));
            }, () => call_api_reauth(`${process.env.REACT_APP_BE_SERVER}/myinfo`)
                .then((data) => {
                    console.log("refreshed")
                    dispatch(updateSigninState(true));
                    dispatch(updateInfo(data));
                }, () => { localStorage.clear() })
            )
    }
    if (!user.isSignedIn) {
        loginButton = (
            <DiscordLoginButton onClick={() => { window.location = `https://discord.com/oauth2/authorize?response_type=code&client_id=1071421098262020169&scope=identify&redirect_uri=${process.env.REACT_APP_BE_SERVER}/oauth-callback` }} />
        )
    } else {
        writePostButton = (
            <Button onClick={() => { dispatch(resetPost()); navigate('/write-post') }}>
                글쓰기
            </Button>
        )
        myPostsButton = (
            <Button onClick={() => navigate('/my-posts')}>
                모집글 관리
            </Button>
        )
        logoutButton = (
            <Button onClick={() => {
                localStorage.clear();
                navigate("/");
                dispatch(updateSigninState(false));
                dispatch(updateInfo({
                    discordTag: "",
                    kakaoOpentalkURL: "",
                    nickname: "",
                    userID: ""
                }))
            }}>
                로그아웃
            </Button>
        )
        // changeNicknameButton = (
        //   <Button onClick={() => {navigate('/change-nickname')}}>
        //     Change nickname
        //   </Button>
        // )
    }
    return (
        <div>
            <Grid container>
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={5}>
                    <Button onClick={() => navigate('/')}>
                        파티찾기 홈
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <Stack direction="row">
                        {loginButton}
                        {user.info.discordTag}
                        {writePostButton}
                        {myPostsButton}
                        {logoutButton}
                    </Stack>
                </Grid>
                <Grid item xs={2}>
                </Grid>
            </Grid>
        </div >
    )

}

export default TitleBar;