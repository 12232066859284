import { Avatar, Button, Card, CardActions, CardContent, Chip, Grid, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setPost } from '../reducers/post';
import { call_api, call_api_delete, call_api_reauth } from '../utils';
import TitleBar from './TitleBar';
import DiscordLogoBlack from '../images/discord-mark-black.svg';

function MyPosts() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [postLoaded, setPostLoaded] = useState(false);
  const [postList, setPostList] = useState([]);
  if (!postLoaded) {
    console.log("load postlist")
    call_api(`${process.env.REACT_APP_BE_SERVER}/post?myPost=true`)
      .then((data) => {
        setPostList(data);
        setPostLoaded(true);
      }, () => call_api_reauth(`${process.env.REACT_APP_BE_SERVER}/post?myPost=true`)
        .then((data) => {
          setPostList(data);
          setPostLoaded(true);
        }))
  }
  const handleEditPost = (event) => {
    call_api(`${process.env.REACT_APP_BE_SERVER}/post?id=${event.target.name}`)
      .then((data) => {
        dispatch(setPost(data));
        navigate("/write-post");
      })
  }
  const handleDeletePost = (event) => {
    call_api_delete(`${process.env.REACT_APP_BE_SERVER}/post?id=${event.target.name}`)
      .then((data) => {
        setPostLoaded(false);
      })
  }
  const posts = postList.map((elem) => {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={2}>
          </Grid>
          <Grid item xs={8} className="home-post">
            <Card sx={{ margin: 1 }}>
              <CardContent>
                <Stack direction="row" spacing={1}>
                  <Typography variant="h4">{elem.Content}</Typography>
                  <Typography variant="h4" style={{ color: "grey" }}>{elem.Progress}</Typography>
                  <Typography variant="h4" style={{ color: "grey" }}>{elem.ProgressDetail}</Typography>
                  <Stack direction="row" justifyContent="flex-end" flexGrow={1}>
                    <Typography variant="h6">게시일: {elem.CreatedAt}</Typography>
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Chip variant="contained" label={elem.PartyType} />
                  <Chip variant="contained" label={elem.Objective} />
                  <Chip avatar={<Avatar src={DiscordLogoBlack} variant="square" />} variant="contained" label={elem.UseDiscord} />
                </Stack>
                <Typography variant="h5">{elem.Requirements}</Typography>
                {/* <Typography variant="h5">{elem.Schedule}</Typography> */}
                <Typography variant="h5">{elem.ItemDistribution}</Typography>
                <Typography variant="h5">{elem.Comment}</Typography>
                <Typography variant="h5">{elem.Jobs}</Typography>
              </CardContent>
              <CardActions>
                <Button size="small" variant="contained" onClick={handleEditPost} name={elem.PostID}>수정하기</Button>
                <Button size="small" variant="contained" color="error" onClick={handleDeletePost} name={elem.PostID}>삭제하기</Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={2}>
          </Grid>
        </Grid>
      </div>
    )
  })
  return (
    <div className="MyPosts">
      <TitleBar />
      {posts}
    </div>
  );
}

export default MyPosts;
