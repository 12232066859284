import './Home.css';

import { useState } from 'react';
import { Alert, Avatar, Button, Card, CardActions, CardContent, Chip, Dialog, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Snackbar, Typography } from '@mui/material';
import { call_api, call_api_reauth, call_api_without_auth } from '../utils';
import { Box, Stack } from '@mui/system';
import DiscordLogoBlack from '../images/discord-mark-black.svg';
import DiscordLogoBlue from '../images/discord-mark-blue.svg';
import KakaotalkLogo from '../images/kakaotalk_sharing_btn_small.png';
import TitleBar from './TitleBar';
import { useSelector } from 'react-redux';


function Home() {
  const [postsLoaded, setPostsLoaded] = useState(false);
  const [posts, setPosts] = useState([]);
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [contacts, setContacts] = useState([]);
  const user = useSelector(state => state.user);
  const handleContactDialogOpen = (event, elem) => {
    setContactDialogOpen(true);
    setContacts([elem.ContactDiscord, elem.ContactKakaoOpentalkURL, elem.ContactOther])
    console.log([elem.ContactDiscord, elem.ContactKakaoOpentalkURL, elem.ContactOther]);
  }
  const handleContactDialogClose = () => {
    setContactDialogOpen(false);
    setTimeout(() => setContacts([]), 300);
  }
  const [copiedOpen, setCopiedOpen] = useState(false);
  const handleCopiedOpen = (event) => {
    setCopiedOpen(true);
  }
  const handleCopiedClose = () => {
    setCopiedOpen(false);
  }
  if (!postsLoaded) {
    call_api_without_auth(`${process.env.REACT_APP_BE_SERVER}/post`)
      .then((data) => {
        setPosts(data)
      });
    setPostsLoaded(true);
  }
  const postList = posts.map((elem) => {
    let viewContacts;
    if (user.isSignedIn) {
      viewContacts = <Button size="small" variant='contained' onClick={(event) => handleContactDialogOpen(event, elem)}>연락처 확인하기</Button>
    }
    return (
      <Grid container spacing={2}>
        <Grid item xs={2}>
        </Grid>
        <Grid item xs={8} className="home-post">
          <Card sx={{ margin: 1 }}>
            <CardContent>
              <Stack direction="row" spacing={1}>
                <Typography variant="h4">{elem.Content}</Typography>
                <Typography variant="h4" style={{ color: "grey" }}>{elem.Progress}</Typography>
                <Typography variant="h4" style={{ color: "grey" }}>{elem.ProgressDetail}</Typography>
                <Stack direction="row" justifyContent="flex-end" flexGrow={1}>
                  <Typography variant="h6">게시일: {elem.CreatedAt}</Typography>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Chip variant="contained" label={elem.PartyType} />
                <Chip variant="contained" label={elem.Objective} />
                <Chip avatar={<Avatar src={DiscordLogoBlack} variant="square" />} variant="contained" label={elem.UseDiscord} />
              </Stack>
              <Typography variant="h5">{elem.Requirements}</Typography>
              {/* <Typography variant="h5">{elem.Schedule}</Typography> */}
              <Typography variant="h5">{elem.ItemDistribution}</Typography>
              <Typography variant="h5">{elem.Comment}</Typography>
              <Typography variant="h5">{elem.Jobs}</Typography>
            </CardContent>
            <CardActions>
              {viewContacts}
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={2}>
        </Grid>
      </Grid>
    )
  })
  return (
    <div className="Home">
      <TitleBar />
      <Box sx={{ flexGrow: 1 }}>
        {postList}
      </Box>
      <Dialog onClose={handleContactDialogClose} open={contactDialogOpen}>
        <List>
          <ListItem disableGutters>
            <ListItemButton key={contacts[0]} onClick={() => { handleCopiedOpen(); window.navigator.clipboard.writeText(contacts[0]) }}>
              <ListItemAvatar>
                <Avatar src={DiscordLogoBlue} variant="square" />
              </ListItemAvatar>
              <ListItemText primary={contacts[0]} />
            </ListItemButton>
          </ListItem>
          <ListItem disableGutters>
            <ListItemButton key={contacts[1]} onClick={() => { window.open(contacts[1]) }}>
              <ListItemAvatar>
                <Avatar src={KakaotalkLogo} variant="square" />
              </ListItemAvatar>
              <ListItemText primary={contacts[1]} />
            </ListItemButton>
          </ListItem>
          <ListItem disableGutters>
            <ListItemButton key={contacts[2]}>
              <ListItemAvatar>
                <Avatar>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={contacts[2]} />
            </ListItemButton>
          </ListItem>
        </List>
      </Dialog>
      <Snackbar open={copiedOpen} autoHideDuration={2000} onClose={handleCopiedClose}>
        <Alert severity="success" elevation={6}>
          복사되었습니다!
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Home;
