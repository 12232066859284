import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

import Home from './pages/Home';
import Login from './pages/Login';
import OAuth from './pages/OAuth';
import rootReducer from './reducers';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import ChangeNickname from './pages/ChangeNickname';
import WritePost from './pages/WritePost';
import MyPosts from './pages/MyPosts';

const root = createRoot(document.getElementById('root'));
const store = configureStore({ reducer: rootReducer });
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="change-nickname" element={<ChangeNickname />} /> */}
        <Route path="write-post" element={<WritePost />} />
        <Route path="login" element={<Login />} />
        <Route path="oauth" element={<OAuth />} />
        <Route path="my-posts" element={<MyPosts />} />
      </Routes>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
