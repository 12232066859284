import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

function OAuth() {
  console.log("oauth")
  const [searchParams] = useSearchParams();
  const [redirect, setRedirect] = useState(false);

  const access_token = searchParams.get("access_token");
  const refresh_token = searchParams.get("refresh_token");
  const next = searchParams.get("next");
  if (!redirect) {
    const storeCredentials = () => new Promise((resolve, reject) => {
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
    })
    storeCredentials().then(setRedirect(true));
  }
  if (redirect) {
    window.location = `${next}`;
  }
  return (
    <div className="OAuth">
    </div>
  );
}

export default OAuth;
