export const RESETPOST = "RESETPOST";
export const SETPOST = "SETPOST";
export const SETDEPARTAT = "SETDEPARTAT";
export const SETJOBS = "SETJOBS";
export const SETCONTENT = "SETCONTENT";
export const SETPARTYTYPE = "SETPARTYTYPE";
export const SETPROGRESS = "SETPROGRESS";
export const SETPROGRESSDETAIL = "SETPROGRESSDETAIL";
export const SETSCHEDULES = "SETSCHEDULES";
export const SETOBJECTIVE = "SETOBJECTIVE";
export const SETITEMDISTRIBUTION = "SETITEMDISTRIBUTION";
export const SETREQUIREMENTS = "SETREQUIREMENTS";
export const SETUSEDISCORD = "SETUSEDISCORD";
export const SETCOMMENTS = "SETCOMMENTS";
export const SETDATACENTER = "SETDATACENTER";
export const SETCONTACTDEFAULTDISCORD = "SETCONTACTDEFAULTDISCORD";
export const SETCONTACTDISCORD = "SETCONTACTDISCORD";
export const SETCONTACTKAKAOOPENTALKURL = "SETCONTACTKAKAOOPENTALKURL";
export const SETCONTACTOTHER = "SETCONTACTOTHER";

export const resetPost = () => ({ type: RESETPOST })
export const setPost = post => ({ type: SETPOST, post })
export const setDepartAt = value => ({ type: SETDEPARTAT, value })
export const setJobs = value => ({ type: SETJOBS, value })
export const setContent = value => ({ type: SETCONTENT, value })
export const setPartyType = value => ({ type: SETPARTYTYPE, value })
export const setProgress = value => ({ type: SETPROGRESS, value })
export const setProgressDetail = value => ({ type: SETPROGRESSDETAIL, value })
export const setSchedules = value => ({ type: SETSCHEDULES, value })
export const setObjective = value => ({ type: SETOBJECTIVE, value })
export const setItemDistribution = value => ({ type: SETITEMDISTRIBUTION, value })
export const setRequirements = value => ({ type: SETREQUIREMENTS, value })
export const setUseDiscord = value => ({ type: SETUSEDISCORD, value })
export const setComments = value => ({ type: SETCOMMENTS, value })
export const setDatacenter = value => ({ type: SETDATACENTER, value })
export const setContactDiscord = value => ({ type: SETCONTACTDISCORD, value })
export const setContactDefaultDiscord = value => ({ type: SETCONTACTDEFAULTDISCORD, value })
export const setContactKakaoOpentalkURL = value => ({ type: SETCONTACTKAKAOOPENTALKURL, value })
export const setContactOther = value => ({ type: SETCONTACTOTHER, value })

const initialState = {
    PostID: null,
    DepartAt: null,
    Jobs: [],
    Content: "",
    PartyType: "공대",
    Progress: "",
    ProgressDetail: null,
    Schedules: [],
    Objective: "클리어 후 파밍",
    ItemDistribution: null,
    Requirements: null,
    UseDiscord: "사용, 마이크 필수",
    Comments: null,
    Datacenter: "한국",
    ContactDiscord: "",
    ContactDefaultDiscord: false,
    ContactKakaoOpentalkURL: "",
    ContactOther: "",
}

export const post = (state = initialState, action) => {
    switch (action.type) {
        case RESETPOST:
            return initialState;
        case SETPOST:
            return action.post;
        case SETDEPARTAT:
            return { ...state, DepartAt: action.value }
        case SETJOBS:
            return { ...state, JobsChange: action.value }
        case SETCONTENT:
            return { ...state, Content: action.value }
        case SETPARTYTYPE:
            return { ...state, PartyType: action.value }
        case SETPROGRESS:
            return { ...state, Progress: action.value }
        case SETPROGRESSDETAIL:
            return { ...state, ProgressDetail: action.value }
        case SETSCHEDULES:
            return { ...state, Schedules: action.value }
        case SETOBJECTIVE:
            return { ...state, Objective: action.value }
        case SETITEMDISTRIBUTION:
            return { ...state, ItemDistribution: action.value }
        case SETREQUIREMENTS:
            return { ...state, Requirements: action.value }
        case SETUSEDISCORD:
            return { ...state, UseDiscord: action.value }
        case SETCOMMENTS:
            return { ...state, Comments: action.value }
        case SETDATACENTER:
            return { ...state, Datacenter: action.value }
        case SETCONTACTDISCORD:
            return { ...state, ContactDiscord: action.value }
        case SETCONTACTDEFAULTDISCORD:
            return { ...state, ContactDefaultDiscord: action.value }
        case SETCONTACTKAKAOOPENTALKURL:
            return { ...state, ContactKakaoOpentalkURL: action.value }
        case SETCONTACTOTHER:
            return { ...state, ContactOther: action.value }
        default:
            return state;
    }
}