export const UPDATEINFO = "UPDATEINFO";
export const UPDATESIGNINSTATE = "UPDATESIGNINSTATE";

export const updateInfo = info => ({ type: UPDATEINFO, info });
export const updateSigninState = state => ({ type: UPDATESIGNINSTATE, state });

const initialState = {
    isSignedIn: false,
    info: {
        discordTag: "",
        kakaoOpentalkURL: "",
        nickname: "",
        userID: ""
    }
};


export const user = (state = initialState, action) => {
    switch (action.type) {
        case UPDATEINFO:
            return {
                ...state,
                info: action.info
            };
        case UPDATESIGNINSTATE:
            return {
                ...state,
                isSignedIn: action.state
            }
        default:
            return state;
    }
};