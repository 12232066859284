import './WritePost.css';
import { Alert, Button, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, Snackbar, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { koKR } from '@mui/x-date-pickers';
import moment from 'moment';
import "moment/locale/ko";
import { call_api, call_api_post, call_api_reauth, call_api_post_reauth } from '../utils';
import { updateInfo, updateSigninState } from '../reducers/userInfo';
import { useNavigate } from 'react-router-dom';
import { resetPost, setComments, setContactDefaultDiscord, setContactDiscord, setContactKakaoOpentalkURL, setContactOther, setContent, setDatacenter, setDepartAt, setItemDistribution, setObjective, setPartyType, setProgress, setProgressDetail, setRequirements, setUseDiscord } from '../reducers/post';
import TitleBar from './TitleBar';
import DPSRole from '../images/DPSRole.png';
import HealerRole from '../images/HealerRole.png';
import TankRole from '../images/TankRole.png';
import DRK from '../images/DarkKnight.png';
import GNB from '../images/Gunbreaker.png';
import WAR from '../images/Warrior.png';
import PLD from '../images/Paladin.png';
import AST from '../images/Astrologian.png';
import SGE from '../images/Sage.png';
import SCH from '../images/Scholar.png';
import WHM from '../images/WhiteMage.png';
import MNK from '../images/Monk.png';
import DRG from '../images/Dragoon.png';
import NIN from '../images/Ninja.png';
import SAM from '../images/Samurai.png';
import RPR from '../images/Reaper.png';
import BRD from '../images/Bard.png';
import MCH from '../images/Machinist.png'
import DNC from '../images/Dancer.png';
import BLM from '../images/BlackMage.png';
import SMN from '../images/Summoner.png';
import RDM from '../images/RedMage.png';
import BLU from '../images/BlueMage.png';
import MeleeDPS from '../images/MeleeDPS.png';
import PhysicalRangedDPS from '../images/PhysicalRangedDPS.png';
import MagicalRangedDPS from '../images/MagicalRangedDPS.png';
import BlockIcon from '@mui/icons-material/Block';
import PersonIcon from '@mui/icons-material/Person';

moment.locale("ko");

function WritePost() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user)
  const post = useSelector(state => state.post)
  if (localStorage.getItem("access_token") && !user.isSignedIn) {
    call_api(`${process.env.REACT_APP_BE_SERVER}/myinfo`)
      .then((data) => {
        dispatch(updateSigninState(true));
        dispatch(updateInfo(data));
      }, () => call_api_reauth(`${process.env.REACT_APP_BE_SERVER}/myinfo`)
        .then((data) => {
          console.log("refreshed")
          dispatch(updateSigninState(true));
          dispatch(updateInfo(data));
        }, () => { localStorage.clear() })
      )
  }
  const navigate = useNavigate();
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const handleSuccessSnackbarClose = () => {
    setOpenSuccessSnackbar(false);
  }
  const handleErrorSnackbarClose = () => {
    setOpenErrorSnackbar(false);
  }
  const [errorContents, setErrorContents] = useState(false);
  const [errorProgress, setErrorProgress] = useState(false);
  const [errorContact, setErrorContact] = useState(false);
  let errorContentsHelpertext;
  let errorProgressHelpertext;
  if (errorContents) {
    errorContentsHelpertext = (
      <FormHelperText>필수 항목입니다</FormHelperText>
    )
  }
  if (errorProgress) {
    errorProgressHelpertext = (
      <FormHelperText>필수 항목입니다</FormHelperText>
    )
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!post.Content) {
      setErrorContents(true);
      setOpenErrorSnackbar(true);
    } else {
      setErrorContents(false);
    }
    if (!post.Progress) {
      setErrorProgress(true);
      setOpenErrorSnackbar(true);
    } else {
      setErrorProgress(false);
    }
    if (!(post.ContactDiscord || post.ContactKakaoOpentalkURL || post.ContactOther)) {
      setErrorContact(true);
      setOpenErrorSnackbar(true);
    } else {
      setErrorContact(false);
    }
    if (post.Content && post.Progress && (post.ContactDiscord || post.ContactKakaoOpentalkURL || post.ContactOther)) {
      call_api_post(`${process.env.REACT_APP_BE_SERVER}/post`, post)
        .then((data) => {
          setOpenSuccessSnackbar(true);
          setTimeout(() => { dispatch(resetPost()); navigate("/my-posts") }, 3000);
        }, () => call_api_post_reauth(`${process.env.REACT_APP_BE_SERVER}/post`, post)
          .then((data) => {
          })
        )
    }
  }
  const handleDepartAtChange = (newValue) => {
    dispatch(setDepartAt(newValue.format("YYYY-MM-DD")))
  }
  const handleJobsChange = (newvalue) => {
    // TODO:
  }
  const handleContentChange = (event) => {
    dispatch(setContent(event.target.value))
    dispatch(setProgress(null))
  }
  const handlePartyTypeChange = (event) => {
    dispatch(setPartyType(event.target.value))
  }
  const handleProgressChange = (event) => {
    dispatch(setProgress(event.target.value))
  }
  const handleProgressDetailChange = (event) => {
    dispatch(setProgressDetail(event.target.value))
  }
  const handleSchedulesChange = (newValue) => {
    // TODO:
  }
  const handleObjectiveChange = (event) => {
    dispatch(setObjective(event.target.value))
  }
  const handleItemDistributionChange = (event) => {
    dispatch(setItemDistribution(event.target.value))
  }
  const handleRequirementsChange = (event) => {
    dispatch(setRequirements(event.target.value))
  }
  const handleUseDiscordChange = (event) => {
    dispatch(setUseDiscord(event.target.value))
  }
  const handleCommentsChange = (event) => {
    dispatch(setComments(event.target.value))
  }
  const handleDatacenterChange = (event) => {
    dispatch(setDatacenter(event.target.value))
  }
  const handleContactDiscordChange = (event) => {
    dispatch(setContactDiscord(event.target.value))
  }
  const handleContactDiscordCheckboxChange = (event) => {
    if (event.target.checked) {
      dispatch(setContactDiscord(user.info.discordTag))
    }
    dispatch(setContactDefaultDiscord(event.target.checked));
  }
  const handleContactKakaoOpentalkURLChange = (event) => {
    dispatch(setContactKakaoOpentalkURL(event.target.value))
  }
  // const handleContactKakaoOpentalkURLCheckboxChange = (event) => {
  //   if (event.target.checked) {
  //     setPost({ ...post, ContactKakaoOpentalkURL: user.info.kakaoOpentalkURL });
  //   }
  //   setDefaultKakaoOpentalkURL(event.target.checked);
  // }
  const handleContactOtherChange = (event) => {
    dispatch(setContactOther(event.target.value))
  }
  const [openJobDialog, setOpenJobDialog] = useState(false);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const handleJobDialogOpen = (current) => {
    setOpenJobDialog(true);
    setSelectedJobs(current);
  }
  const handleJobDialogClose = () => {
    setOpenJobDialog(false);
  }
  const handleJobDialogConfirm = () => {
    setOpenJobDialog(false);
    // TODO: store selected jobs to button
  }
  const PageTitle = (props) => {
    if (props.editing) {
      return (
        <Typography variant="h5">모집글 수정</Typography>
      )
    } else {
      return (
        <Typography variant="h5">새 모집글 작성</Typography>
      )
    }
  }
  const [defaultKakaoOpentalkURL, setDefaultKakaoOpentalkURL] = useState(false);
  const progress_map = {
    "마의 전당 판데모니움: 연옥편(영웅 난이도)": ["1층", "2층", "3층", "4층"],
    "마의 전당 판데모니움: 변옥편(영웅 난이도)": ["1층", "2층", "3층", "4층"],
    "절 바하무트 토벌전": ["1페이즈", "2페이즈", "3페이즈", "4페이즈", "5페이즈"],
    "절 알테마 웨폰 파괴작전": ["1페이즈", "2페이즈", "3페이즈", "4페이즈", "5페이즈"],
    "절 알렉산더 토벌전": ["1페이즈", "2페이즈", "3페이즈", "4페이즈"],
    "절 용시전쟁": ["1페이즈", "2페이즈", "3페이즈", "4페이즈", "5페이즈", "6페이즈", "7페이즈"],
  }
  let progressMenuItems = post.Content === "" ? <MenuItem disabled value="">컨텐츠를 먼저 선택해주세요</MenuItem>: progress_map[post.Content]?.map((elem) => {
    return (<MenuItem value={elem}>{elem}</MenuItem>)
  })
  return (
    <div className="WritePost">
      <TitleBar />
      <Box component="form" onSubmit={handleSubmit} sx={{ flexGrow: 1, '& .MuiTextField-root': { m: 1 }, '& .MuiFormControl-root': { m: 1 } }}>
        <Grid container spacing={2}>
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={10}>
            <Card sx={{ margin: 1, padding: 3 }}>
              <PageTitle editing={post.PostID} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl required fullWidth error={errorContents}>
                    <InputLabel id="content-select-label" shrink={true}>컨텐츠 (필수)</InputLabel>
                    <Select
                      labelId="content-select-label"
                      id="content-select"
                      value={post.Content}
                      label={"컨텐츠 (필수)"}
                      onChange={handleContentChange}
                      notched={true}
                    >
                      <MenuItem value={"마의 전당 판데모니움: 연옥편(영웅 난이도)"}>마의 전당 판데모니움: 연옥편(영웅 난이도)</MenuItem>
                      <MenuItem value={"마의 전당 판데모니움: 변옥편(영웅 난이도)"}>마의 전당 판데모니움: 변옥편(영웅 난이도)</MenuItem>
                      <MenuItem value={"절 용시전쟁"}>절 용시전쟁</MenuItem>
                      <MenuItem value={"절 알렉산더 토벌전"}>절 알렉산더 토벌전</MenuItem>
                      <MenuItem value={"절 알테마 웨폰 파괴작전"}>절 알테마 웨폰 파괴작전</MenuItem>
                      <MenuItem value={"절 바하무트 토벌전"}>절 바하무트 토벌전</MenuItem>
                    </Select>
                    {errorContentsHelpertext}
                  </FormControl>
                  <FormControl required fullWidth error={errorProgress}>
                    <InputLabel id="progress-select-label" shrink={true}>진도 (필수)</InputLabel>
                    <Select
                      displayEmpty
                      labelId="progress-select-label"
                      id="progress-select"
                      value={post.Progress}
                      label={"진도 (필수)"}
                      onChange={handleProgressChange}
                      notched={true}
                      // renderValue={post.Content === "" ? undefined : () => "컨텐츠를 먼저 선택해주세요"}
                    >
                      {progressMenuItems}
                    </Select>
                    {errorProgressHelpertext}
                  </FormControl>
                  <TextField
                    fullWidth
                    id="progress-detail"
                    label='세부 진도'
                    placeholder="예) 주사위 패턴"
                    variant="outlined"
                    onChange={handleProgressDetailChange}
                    InputLabelProps={{ shrink: true }}
                  />
                  <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ko" localeText={koKR.components.MuiLocalizationProvider.defaultProps.localeText}>
                    <DatePicker
                      label="출발 예정일"
                      value={post.DepartAt}
                      onChange={handleDepartAtChange}
                      renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} sx={{ width: '100%' }} />}
                    />
                  </LocalizationProvider>
                  <TextField
                    fullWidth
                    label="아이템 분배 방식"
                    id="item-distribution"
                    multiline
                    rows={4}
                    placeholder="예) 무기: 딜>힐>탱"
                    variant="outlined"
                    onChange={handleItemDistributionChange}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    fullWidth
                    label="모집 조건"
                    id="requirements"
                    multiline
                    rows={4}
                    placeholder="예) 현역 영식 1주클 경험자, 문의시에 서버와 닉네임을 알려주세요"
                    variant="outlined"
                    onChange={handleRequirementsChange}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    fullWidth
                    label="기타 사항"
                    id="comments"
                    multiline
                    rows={4}
                    placeholder="예) 시간 잘 지켜주세요 ..."
                    variant="outlined"
                    onChange={handleCommentsChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="column">
                    <FormControl>
                      <FormLabel id="party-type-label">파티 형태</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="party-type-label"
                        name="party-type"
                        defaultValue={post.PartyType}
                        onChange={handlePartyTypeChange}
                      >
                        <FormControlLabel value="공대" control={<Radio />} label="공대" />
                        <FormControlLabel value="고정팟" control={<Radio />} label="고정팟" />
                      </RadioGroup>
                    </FormControl>
                    <FormControl>
                      <FormLabel id="objective-label">파티 목적</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="objective-label"
                        defaultValue="클리어 후 파밍"
                        name="objective"
                        onChange={handleObjectiveChange}
                      >
                        <FormControlLabel value="클리어 후 파밍" control={<Radio />} label="클리어 후 파밍" />
                        <FormControlLabel value="클리어" control={<Radio />} label="클리어" />
                        <FormControlLabel value="파밍" control={<Radio />} label="파밍" />
                      </RadioGroup>
                    </FormControl>
                    <FormControl>
                      <FormLabel id="use-discord-label">디스코드 사용</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="use-discord-label"
                        name="useDiscord"
                        defaultValue="사용, 마이크 필수"
                        onChange={handleUseDiscordChange}
                      >
                        <FormControlLabel value="사용, 마이크 필수" control={<Radio />} label="사용, 마이크 필수" />
                        <FormControlLabel value="사용, 마이크 필수 아님" control={<Radio />} label="사용, 마이크 필수 아님" />
                        <FormControlLabel value="미사용" control={<Radio />} label="미사용" />
                      </RadioGroup>
                    </FormControl>
                    <FormControl>
                      <FormLabel id="datacenter-label">데이터센터</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="datacenter-label"
                        name="datacenter"
                        defaultValue="한국"
                        onChange={handleDatacenterChange}
                      >
                        <FormControlLabel value="한국" control={<Radio />} label="한국" />
                        <FormControlLabel value="일본" control={<Radio />} label="일본" />
                        <FormControlLabel value="유럽" control={<Radio />} label="유럽" />
                        <FormControlLabel value="미국" control={<Radio />} label="미국" />
                      </RadioGroup>
                    </FormControl>
                    <FormControl>
                      <FormLabel id="jobs-label">직업 구성</FormLabel>
                      <Stack direction="row">
                        <Button onClick={handleJobDialogOpen}><img src={TankRole} alt="Tank" /></Button>
                        <Button><img src={TankRole} alt="Tank" /></Button>
                        <Button><img src={HealerRole} alt="Heal" /></Button>
                        <Button><img src={HealerRole} alt="Heal" /></Button>
                        <Button><img src={DPSRole} alt="DPS" /></Button>
                        <Button><img src={DPSRole} alt="DPS" /></Button>
                        <Button><img src={DPSRole} alt="DPS" /></Button>
                        <Button><img src={DPSRole} alt="DPS" /></Button>
                      </Stack>
                    </FormControl>
                    <FormControl>
                      <FormLabel id="schedules-label">진행 일정</FormLabel>
                      {/* TODO: day, time picker */}
                    </FormControl>
                    <FormControl required>
                      <FormLabel id="contacts-label">연락처 (한 개 이상 필수)</FormLabel>
                      <Stack direction="row" alignItems="center">
                        <TextField sx={{ width: 400 }} error={errorContact} id="contact-discord" InputLabelProps={{ shrink: true }} label="디스코드 연락처" variant="outlined" placeholder='예) 디스코드#0000' InputProps={{ readOnly: post.ContactDefaultDiscord }} value={post.ContactDiscord} onChange={handleContactDiscordChange} />
                        <Checkbox onChange={handleContactDiscordCheckboxChange} checked={post.ContactDefaultDiscord} /><Typography>저장된 정보 입력</Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <TextField fullWidth error={errorContact} id="contact-kakaoopentalk" InputLabelProps={{ shrink: true }} label="카카오 오픈채팅" variant="outlined" placeholder='예) https://open.kakao.com/o/...' InputProps={{ readOnly: defaultKakaoOpentalkURL }} value={post.ContactKakaoOpentalkURL} onChange={handleContactKakaoOpentalkURLChange} />
                        {/* <Checkbox onChange={handleContactKakaoOpentalkURLCheckboxChange} checked={defaultKakaoOpentalkURL} /> 작성자의 오픈카톡 사용 */}
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <TextField fullWidth error={errorContact} id="contact-other" InputLabelProps={{ shrink: true }} label="기타 연락수단" variant="outlined" placeholder='예) 빛의영자하나@카벙클' onChange={handleContactOtherChange} />
                      </Stack>
                    </FormControl>

                  </Stack>
                </Grid>
              </Grid>
              <Stack direction="row" justifyContent="flex-end">
                <Button type="submit" variant="contained">
                  SUBMIT
                </Button>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={1}>
          </Grid>
        </Grid>
      </Box>
      <Snackbar open={openErrorSnackbar} autoHideDuration={3000} onClose={handleErrorSnackbarClose}>
        <Alert onClose={handleErrorSnackbarClose} severity="error" sx={{ width: '100%' }} variant="filled">
          작성하지 않은 필수항목이 있습니다. 다시 확인해주세요.
        </Alert>
      </Snackbar>
      <Snackbar open={openSuccessSnackbar} autoHideDuration={3000} onClose={handleSuccessSnackbarClose}>
        <Alert onClose={handleSuccessSnackbarClose} severity="success" sx={{ width: '100%' }} variant="filled">
          등록되었습니다! 내 글 목록으로 이동합니다...
        </Alert>
      </Snackbar>
      <Dialog open={openJobDialog} onClose={handleJobDialogClose}>
        <DialogTitle>직업 선택</DialogTitle>
        <DialogContent sx={{ p: 2 }} dividers>
          <Stack direction="row" alignItems="center">
            <IconButton><img src={TankRole} alt="Tank Role" width="36" height="36" /></IconButton>
            <Typography variant="h6">방어 역할</Typography>
            <IconButton><img src={PLD} alt="Paladin" width="36" height="36" /></IconButton>
            <IconButton><img src={WAR} alt="Warrior" width="36" height="36" /></IconButton>
            <IconButton><img src={DRK} alt="Dark Knight" width="36" height="36" /></IconButton>
            <IconButton><img src={GNB} alt="Gunbreaker" width="36" height="36" /></IconButton>
          </Stack>
          <Divider />
          <Stack direction="row" alignItems="center">
            <IconButton><img src={HealerRole} alt="Helaer Role" width="36" height="36" /></IconButton>
            <Typography variant="h6">회복 역할</Typography>
            <Stack direction="column">
              <Stack direction="row">
                <IconButton><img src={WHM} alt="White Mage" width="36" height="36" /></IconButton>
                <IconButton><img src={AST} alt="Astrologian" width="36" height="36" /></IconButton>
                <IconButton><img src={SCH} alt="Scholar" width="36" height="36" /></IconButton>
                <IconButton><img src={SGE} alt="Sage" width="36" height="36" /></IconButton>
              </Stack>
            </Stack>
          </Stack>
          <Divider />
          <Stack direction="row" alignItems="center">
            <Stack direction="column">
              <Stack direction="row" alignItems="center">
                <IconButton><img src={DPSRole} alt="DPS Role" width="36" height="36" /></IconButton>
                <Typography variant="h6">공격 역할</Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <IconButton><img src={MeleeDPS} alt="Melee DPS" width="24" height="24" /></IconButton>
                <Typography variant="h7">근거리 공격</Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <IconButton><img style={{ "opacity": 0.5 }} src={MNK} alt="Monk" width="36" height="36" /></IconButton>
                <IconButton><img style={{ "opacity": 0.5 }} src={DRG} alt="Dragoon" width="36" height="36" /></IconButton>
                <IconButton><img style={{ "opacity": 0.5 }} src={NIN} alt="Ninja" width="36" height="36" /></IconButton>
                <IconButton><img style={{ "opacity": 0.5 }} src={SAM} alt="Samurai" width="36" height="36" /></IconButton>
                <IconButton><img style={{ "opacity": 0.5 }} src={RPR} alt="Reaper" width="36" height="36" /></IconButton>
              </Stack>
              <Stack direction="row" alignItems="center">
                <IconButton><img src={PhysicalRangedDPS} alt="Physical Ranged DPS" width="24" height="24" /></IconButton>
                <Typography variant="h7">원거리 물리 공격</Typography>
              </Stack>
              <Stack direction="row">
                <IconButton><img src={BRD} alt="Bard" width="36" height="36" /></IconButton>
                <IconButton><img src={MCH} alt="Machinist" width="36" height="36" /></IconButton>
                <IconButton><img src={DNC} alt="Dancer" width="36" height="36" /></IconButton>
              </Stack>
              <Stack direction="row" alignItems="center">
                <IconButton><img src={MagicalRangedDPS} alt="Magical Ranged DPS" width="24" height="24" /></IconButton>
                <Typography variant="h7">원거리 물리 공격</Typography>
              </Stack>
              <Stack direction="row">
                <IconButton><img src={BLM} alt="Black Mage" width="36" height="36" /></IconButton>
                <IconButton><img src={SMN} alt="Summoner" width="36" height="36" /></IconButton>
                <IconButton><img src={RDM} alt="Red Mage" width="36" height="36" /></IconButton>
                <IconButton><img src={BLU} alt="Blue Mage" width="36" height="36" /></IconButton>
              </Stack>
            </Stack>
          </Stack>
          <Divider />
          <Stack direction="row" alignItems="center">
            <Button variant="contained" color="success"><PersonIcon /></Button>
            <Typography variant="h6">자유</Typography>
          </Stack>
          <Divider />
          <Stack direction="row" alignItems="center">
            <Button variant="contained" color="error"><BlockIcon /></Button>
            <Typography variant="h6">불필요</Typography>
          </Stack>
        </DialogContent>
        <DialogActions dividers>
          <Button variant="contained">확인</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default WritePost;
