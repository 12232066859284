import { useSearchParams } from 'react-router-dom';

function Login() {
  const [searchParams] = useSearchParams();

  const code = searchParams.get("code");
  window.location = `${process.env.REACT_APP_BE_SERVER}/login?code=${code}`;
  return (
    <div className="Login">
    </div>
  );
}

export default Login;
